@media only screen and (max-width: 854px)  
{ 
	.hidden  
    { 
        display: none !important;
    } 
} 

@media only screen and (max-width: 600px) {
  .mobile-hide{ display: none !important; }
}
@media only screen and (max-width: 854px) {
  .mobile{ display: inline !important; }
}
@media only screen and (min-width: 854px) {
  .desktop-hide{ display: none !important; }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  background-color: lightgray;
  height: 100%;
}
