a:hover {
  cursor: pointer;
}

.login .logo {
  background-image : rgb(236, 174, 40);
  background: radial-gradient(circle, rgba(255,255,255,0.9557072829131653) 10%, rgba(218,173,74,1) 90%);
}

@media  (max-width:580px) {
  .login  {
    display: none;
    
  }
  
} 
